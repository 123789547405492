import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { generateUsername } from 'friendly-username-generator'
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth';
import useClevertap from './useClevertap';
import { trackMetaEvent } from '../tracking/metaPixel';
import { trackTikTokEvent } from '../tracking/tiktokPixel';
import { META_EVENTS, TIKTOK_EVENTS } from '../constants/dataConstant';
import { createSolWallet, updateSolWallet } from '../endpoints/api';
// const auth = getAuth();

const useAsset = () => {

    const [accessToken, setAccessToken] = useState("");
    const [userData, setUserData] = useState({});
    const [userId, setUserId] = useState("");
    const [userCreated, setUserCreated] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [username, setUsername] = useState("");
    const [user, setUser] = useState(null);
    const { onUserLogin, handleEventPushClick } = useClevertap()



    useEffect(() => {
        const auth = getAuth();
        // Check if user is already logged in
        const unsubscribe = auth.onAuthStateChanged((user) => {
          if (user) {
            // console.log("USER ", user)
            setUserData(user);
            user.getIdToken().then((token) => {
              // console.log("TOKEN ", token)
              setAccessToken(token);
            });
          } else {
            setAccessToken(null);
          }
        });
    
        return () => unsubscribe(); // Cleanup the event listener on unmount
      }, []);

      useEffect(() => {
        if (accessToken && accessToken.length > 0) {
          getUser();
        }
      }, [accessToken])

      function googleLogout() {
        const auth = getAuth();
        const userEmail = auth.currentUser.email
    
        // Sign out the currently authenticated user
        auth
          .signOut()
          .then(() => {
            setAccessToken("");
            // console.log("Successfully logged out");
            localStorage.setItem("loggedIn", false);
            handleEventPushClick("MUSICNERD_LOGOUT", {
              email: userEmail
            });
            window.location.href = "/"; // Reload to the main domain
          })
          .catch((error) => {
            console.error("Logout error:", error);
          });
      }
      const getUser = async () => {
        if ((accessToken && accessToken.length > 0)) {
          // setClaimState("claiming");
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_ASSET_API}/api/v1/user/profile`,
              {
                headers: {
                  Authorization: accessToken,
                  "Content-Type": "application/json",
                },
              }
            );
            if (response && response.data) {
              // console.log('inside the resp')
              localStorage.setItem("loggedIn", true);
              setUserId(response.data.data.uuid)
              setUsername(response.data.data.username)
              setUserInfo(response.data.data)
              let username = localStorage.getItem("username");
              if (!username) {
                username = response.data.data.username
                localStorage.setItem("username", username);
              }
              createSolWalletForUser(username, response.data.data.uuid);
              // console.log('username is', response.data.data.username)

              setUserCreated(true);
              onUserLogin({
                email: response?.data?.data?.email ? response?.data?.data?.email : "inboxdev.b@gmail.com",
                name: response?.data?.data?.firstname,
              })
              // createCollectible();
            }
            // return response.data;
          } catch (error) {
            console.log(error);
    
            if (error.response && error.response.status === 401) {
              // Call the createUser function here
              let username = localStorage.getItem("username");
              if (!username) {
                username = generateUsername();
                localStorage.setItem("username", username);
              }
              await createUser(username);
            } else {
              console.log(error);
            }
          }
        } else {
          console.log("no access token");
        }
      };

  // const generateUsername = (username, uuid) => {
  //   // Remove spaces and special characters from the username
  //   const cleanUsername = username.replace(/[^a-zA-Z0-9]/g, "");

  //   // Get the last three characters of the uuid
  //   const lastThreeCharacters = uuid.slice(-3);

  //   const modifiedUsername = cleanUsername + lastThreeCharacters;

  //   return modifiedUsername;
  // };

  const handleSolWalletUpdate = async (username, uuid) => {
    try {
      // Check if we already have a wallet with same uuid
      const storedUuid = localStorage.getItem("walletUuid");
      
      if (storedUuid === uuid) {
        console.log("Wallet already exists for this uuid");
        return {
          data: {
            success: true,
            walletAddress: localStorage.getItem("solwallet")
          }
        };
      }

      // Backend will check if wallet exists for this uuid and create if needed
      let response = await updateSolWallet(username, uuid);
      // console.log("response SOL WALLET update", response);
      
      if (response.data.success) {
        localStorage.setItem("solwallet", response.data.walletAddress);
        localStorage.setItem("walletUuid", uuid);
      }
      return response.data;
    } catch (e) {
      console.log("error updating sol wallet", e);
      return {
        success: false,
        message: e.message
      };
    }
  };

  const handleSolWalletCreate = async (username) => {
    try {
      // Only for anonymous users
      let response = await createSolWallet(username);
      // console.log("response SOL WALLET create", response);
      
      if (response.data.success) {
        localStorage.setItem("solwallet", response.data.walletAddress);
        localStorage.removeItem("walletUuid"); // Clear any existing uuid
      }
      return response.data;
    } catch (e) {
      console.log("error creating sol wallet", e);
      return {
        success: false,
        message: e.message
      };
    }
  };

  const createSolWalletForUser = async (username, uuid) => {
    // console.log("createSolWalletForUser", username, uuid)
    try {
      // For logged-in users, just use update endpoint which handles creation if needed
      if (uuid) {
        return await handleSolWalletUpdate(username,uuid);
      } 
      
      // For anonymous users, only create if no wallet exists
      let solWallet = localStorage.getItem("solwallet");
      if (!solWallet) {
        return await handleSolWalletCreate(username);
      }
      
      return null;
    } catch (e) {
      console.log("error in createSolWalletForUser", e);
      return {
        success: false,
        message: e.message
      };
    }
  }
  const createUser = async (username) => {
    console.log("USERNAME FOR CREATE USER", username);
    // localStorage.setItem("askForUsername", true);
    //write a condition to ensure there is no user with same username
    const firebaseUser = getAuth().currentUser;
    if (firebaseUser !== null) {
      // const username = generateUsername(
      //   firebaseUser.displayName,
      //   firebaseUser.uid
      // );
      if (username && username !== null) {
        console.log("username for new user", firebaseUser.displayName)
        const response = axios
          .post(
            `${process.env.REACT_APP_ASSET_API}/api/v1/user/profile`,
            {
              uuid: firebaseUser.uid,
              email: firebaseUser.email ? firebaseUser.email : "inboxdev.b@gmail.com",
              firstname: firebaseUser.displayName,
              username: firebaseUser.displayName,
              // username: username,
              status: "MN",
              profilepic: firebaseUser.photoURL,
              mobile:
                firebaseUser.phoneNumber !== null
                  ? parseInt(firebaseUser.phoneNumber)
                  : null,
            },
            {
              headers: {
                Authorization: `${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            trackMetaEvent('track', META_EVENTS.ACCOUNT_CREATED_NEW_SIGNUP, firebaseUser.email, firebaseUser.uid, {
              content_name: 'Signup Completion',
              content_category: 'Signup Process',
              method: 'Google Sign-In'
            })
            trackTikTokEvent(TIKTOK_EVENTS.ACCOUNT_CREATED_NEW_SIGNUP, firebaseUser.email, firebaseUser.uid, {
              content_id: "Account Created",
              content_category: "Signup Process",
              content_name: "Signup Completion"
            })
            // if (window.fbq) {
            //   // console.log("fbq", window.fbq);
            //   window.fbq('track', 'CompleteRegistration');
            // }
            createSolWalletForUser(username, firebaseUser.uid);
            setUserCreated(true);          
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const claimNft = async(userId, artistName, artistId, artistImage, level, right, time, percentile, wallet) => {
    try {

      const response = await axios.post(
        `https://cruncher.asset.money/user/mn/badge`,
        {
          userId: userId,
          artistName: artistName,
          artistId: artistId,
          artistImage: artistImage,
          level: level,
          extraData: {
            right: right,
            time: time,
            percentile: percentile,
            wallet: wallet
          }
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      
      let data = response.data;
      console.log("CLAIM NFT RESPONSE", data)
      if(data?.status === "ok") {
        return { success : true }
      } else {
        return { success : false }
      }
      
    } catch(err) {
      console.log(err.message)
      return { success : false }
    }
  }

  return {googleLogout, getUser, createUser, accessToken, userData, userCreated, userId, setAccessToken, setUserData,setUserCreated, setUser, setUserId, username, userInfo, claimNft, createSolWalletForUser}
}

export default useAsset;