import axios from 'axios';
import axiosApiInstance from '../config/axios';

const userUrl = 'api/v1';

const baseURL = process.env.REACT_APP_ASSET_API;

export const getUserInfo = async (accessToken: string) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ASSET_API}/api/v1/user/profile`,
      {
        headers: {
          Authorization: accessToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (response && response.data) {
      return response.data.data
    } else {
      return null
    }
  } catch (error) {
    console.log("error getting userInfo", error)
  }
  return null
}

export const getData = () => {
    return axiosApiInstance.get(`${userUrl}/data`)
};

export const searchArtist = (artistName: string) => {
    return axios.get(`${baseURL}/${userUrl}/music/artist/search/${artistName}`)
    // https://users.stage.asset.money/api/v1/music/artist/search/dra
}

export const getArtistSongs = (artistId: string) => {
    return axios.get(`${baseURL}/${userUrl}/music/artist/quiz/${artistId}`)
}

export const getLeaderboard = (artistId: string) => {
    return axios.get(`${baseURL}/${userUrl}/user/leaderboard/${artistId}`)
}

export const putArtistSongs = (body: any, accessToken: any) => {
    // console.log("accessToken inside the api", accessToken)
    return axios.put(`${baseURL}/${userUrl}/user/leaderboard`,
    body,
    {
        headers: {
          Authorization: accessToken,
          "Content-Type": "application/json",
        },
      })
}
export const postArtistSongs = (body: any) => {
    return axios.post(`${baseURL}/${userUrl}/user/leaderboard`,
    body,
    {
        headers: {
          "Content-Type": "application/json",
        },
      })
}

export const postUserWallet = (body: any) => {
  return axios.post(`https://users.asset.money/api/v1/user/profile/wallet`,
  body,
  {
      headers: {
        "Content-Type": "application/json",
      },
    })
}

export const getGlobalLeaderboard = () => {
  return axios.get(`${baseURL}/${userUrl}/user/artist/leaderboard`)
}

export const getArtistScores = (artistId: string) => {
  return axios.get(`${baseURL}/${userUrl}/user/artist/scores/${artistId}`)
}

export const postArtistScore = (body: any,accessToken: any) => {
  return axios.post(`${baseURL}/${userUrl}/user/badges`,
  body,
  {
      headers: {
        Authorization: accessToken,
        "Content-Type": "application/json",
      },
    })
}

export const postArtistEvent = async (artistId: string, artistName: string, artistImage: string, action: string) => {
  try { 
    const response = await axios.post(`https://cruncher.asset.money/artist/data`, {
      artistName: artistName,
      artistId: artistId,
      artistImage: artistImage,
      action: action
    })
    const data = response.data
    return data
  } catch(err) {
    console.log(err)
    return null
  }
}

export const postUserLocation = async (
  username: string, 
  userId: string, 
  country: string,
  region: string
) => {
  console.log('country', country, region)
  console.log("inside post user location", username);
  try {
    const response = await axios.post(
      // `http://localhost:8077/user/location/${username}`,
            `https://cruncher.asset.money/user/location/${username}`,
      {
        userId: userId,
        country: country,
        region: region,
      }
    );  
    console.log("response is", response);
    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    console.log('Error posting user location', error);
    return null;
  }
} 

export const getUserTotalScorecardBadges = (uuid: string) => {
  return axios.get(`${baseURL}/${userUrl}/user/badges/${uuid}`)
}

export const postMetaConversionsAPIEvent = (body: any) => {
  return axios.post(`https://cruncher.asset.money/meta/v2/track-event`, body)
}

export const postTikTokEventsAPIEvent = (body: any) => {
  return axios.post(`https://cruncher.asset.money/tiktok/track-event`, body)
}

export const getReferralCount = (uuid: string) => {
  return axios.get(`https://cruncher.asset.money/misc/referral/${uuid}`)
}

export const checkUserReferral = async (createdAt: string) => {
  try {
    console.log("createdAt", createdAt);

    if (!createdAt) return false;

    // Convert string to number if it's a timestamp
    const timestamp = parseInt(createdAt);
    if (isNaN(timestamp)) {
      // console.log("Invalid timestamp format");
      return false;
    }
    const created = new Date(timestamp);
    // console.log("created", created);
    const now = new Date();
    
    // Check if the created date is valid
    if (created.toString() === 'Invalid Date') {
      return false;
    }
    // Calculate time difference in minutes
    const timeDiffInMinutes = (now.getTime() - created.getTime()) / (1000 * 60);
    // Check if user was created within last 2 minutes
    if (timeDiffInMinutes <= 2) {
      return true;
    } else {
      return false;
    }

  } catch (error) {
    return false;
  }
}

export const postUserQuizAttempt = (body: any) => {
  return axios.post(`https://cruncher.asset.money/user/attempts`, body)
}

export const INCREMENT_ARTIST_SEARCH = "INCREMENT_ARTIST_SEARCH";
export const INCREMENT_ARTIST_GAMEPLAY = "INCREMENT_ARTIST_GAMEPLAY";
export const INCREMENT_ARTIST_SIGNUP = "INCREMENT_ARTIST_SIGNUP";
export const INCREMENT_ARTIST_SHARE = "INCREMENT_ARTIST_SHARE";