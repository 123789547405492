import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  User,
} from "firebase/auth";
import {
  getArtistSongs,
  postArtistSongs,
  putArtistSongs,
  getArtistScores,
  postArtistScore,
  postArtistEvent,
  INCREMENT_ARTIST_GAMEPLAY,
  INCREMENT_ARTIST_SIGNUP,
  postUserWallet,
  postUserLocation,
  getUserTotalScorecardBadges,
  getUserInfo,
  checkUserReferral,  
} from "../endpoints/api";
import useAsset from "../hooks/useAsset";
import MuzifyLogo from "../assets/images/MuzifyLogo.svg";
import { usePostHog } from "posthog-js/react";
import VictoryAudio from "../assets/audio/a37ca98e-4559-4a5f-a673-f53b5c65f99c.mp3";
import loseAudio from "../assets/audio/b86f3d06-914b-47ff-a75d-8db9631532d8.mp3";
import Confetti from "react-confetti";
import { generateUsername } from "friendly-username-generator";
import useClevertap from "../hooks/useClevertap";
import Header from "../components/Header";
import ScoreCard from "../components/ScoreCard";
import clevertap from "clevertap-web-sdk";
import { CLEVERTAP_ACCOUNT_ID } from "../constants/dataConstant";
import axios from "axios"; // Make sure axios is installed
import { getCurrentDate } from "../common_functions/Common";
import { MUSICNERD_ACCOUNT_CREATED, MUSICNERD_ARTIST_QUIZ_RESPONDED, MUSICNERD_SAVE_SCORE, MUSICNERD_SIGNUP, MUSICNERD_START_OVER, MUSICNERD_WEBPOPUP_2 } from "../constants/events";

const zero = [
  "Let's try this again!",
  "Hit reset and crush this time?",
  "Let's bounce back. Try again",
  "Time for a comeback?",
];
const one = [
  "Don't give up!",
  "Brush off, bounce back",
  "Keep vibing, don't trip",
  "Stay in the game, you got this!",
];
const two = [
  "You can do better.",
  "Level up, you got more in you!",
  "Try again, it's on you",
  "You got more in the tank, replay?",
];
const three = [
  "Better luck next time.",
  "On to the Next, You're Gonna Ace It!",
  "Brush it Off, Next Round's Yours!",
  "Next Time's Your Time!",
];
const four = [
  "Keep trying!",
  "Keep Hustling, You're on the Grind!",
  "Keep pushing; success is the vibe.",
  "No cap, the grind never stops",
];
const five = [
  "Room for improvement.",
  "Let's Level It Up!",
  "Time to Glow Up, always room to shine",
  "You're on the rise",
];
const ten = [
  "Outstanding!",
  "You're a Legend!",
  "OMG, you aced it!",
  "Boss Level: Flawless Victory",
];
const six = [
  "Decent effort!",
  "Solid moves on this one!",
  "Keep crafting your story!",
  "You're on the map, go on!",
];
const seven = [
  "Good job!",
  "Killing the Game: Major Vibes!",
  "Vibes on Point: Keep the Energy!",
  "Game Strong: Keep going!",
];
const eight = [
  "Very good!",
  "Vibes on Fleek: You're Nailing It!",
  "Lowkey Killing It, Highkey Impressive!",
  "Highkey Impressive: You're Killing the Game!",
];
const nine = [
  "Excellent!",
  "Straight Fire: You're Absolutely Killing It!",
  "Lit Levels: You're Slaying, Keep It Up!",
  "Boss vibes only!",
];

// const formatArtistNameForUrl = (name) => {
//   // Special case for Wu-Tang Clan
//   return name
//     .toLowerCase()
//     .replace(/\s*-\s*/g, '')  // Remove spaces around hyphens
//     .replace(/[^a-z0-9]+/g, '-')  // Replace other special chars with dash
//     .replace(/^-+|-+$/g, '');  // Remove leading/trailing dashes
// };

const ScoreScreen = () => {
  const posthog = usePostHog();

  const [showAuth, setShowAuth] = useState(false);
  const [buttonText, setButtonText] = useState("Sign up");
  // const [user, setUser] = useState<User | null>(null);
  const [artistImage, setArtistImage] = useState("");
  const [artistName, setArtistName] = useState("");
  const [quizData, setQuizData] = useState([]);
  const { id } = useParams();
  // const rightAnswer = parseInt(right);
  const [right, setRight] = useState(null);
  const [time, setTime] = useState(null);
  const [randomUsername, setRandomUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [savedData, setSavedData] = useState(false);
  const [text, setText] = useState("");
  const [artistScores, setArtistScores] = useState([]);
  const [percentile, setPercentile] = useState(0);
  const [userClaimNft, setUserClaimNft] = useState(false);
  const [rightLoader, setRightLoader] = useState(true);
  const [sentPopup, setSentPopup] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const location = useLocation();
  // console.log("LOCATION ", location);

  const {
    getUser,
    accessToken,
    setAccessToken,
    user,
    setUser,
    userInfo,
    createUser,
    claimNft,
  } = useAsset();
  const navigate = useNavigate();
  const auth = getAuth();
  const { handleEventPushClick } = useClevertap();

  const [userCountry, setUserCountry] = useState("");
  const [userRegion, setUserRegion] = useState("");
  const [locationDataReady, setLocationDataReady] = useState(false);

  let copyToClipboard = () => {
    const textToCopy = document.getElementById("textToCopy")?.textContent || "";
    navigator.clipboard.writeText(textToCopy);
  };

  const textDisplay = (rightAnswer) => {
    if (quizData?.length) {
      if (rightAnswer === 0) {
        setText(zero[Math.floor(Math.random() * zero.length)]);
      } else if (rightAnswer === 10) {
        setText(one[Math.floor(Math.random() * one.length)]);
      } else if (rightAnswer === 20) {
        setText(two[Math.floor(Math.random() * two.length)]);
      } else if (rightAnswer === 30) {
        console.log("im being called");
        setText(three[Math.floor(Math.random() * three.length)]);
      } else if (rightAnswer === 40) {
        setText(four[Math.floor(Math.random() * four.length)]);
      } else if (rightAnswer === 50) {
        setText(five[Math.floor(Math.random() * five.length)]);
      } else if (rightAnswer === quizData.length * 10) {
        setText(ten[Math.floor(Math.random() * ten.length)]);
      } else if (rightAnswer === 60) {
        setText(six[Math.floor(Math.random() * six.length)]);
      } else if (rightAnswer === 70) {
        setText(seven[Math.floor(Math.random() * seven.length)]);
      } else if (rightAnswer === 80) {
        setText(eight[Math.floor(Math.random() * eight.length)]);
      } else if (rightAnswer === 90) {
        setText(nine[Math.floor(Math.random() * nine.length)]);
      } else {
        return "Well done";
      }
    }
  };

  const sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const recordUserTotalBadges = async (uuid) => {
    // console.log("Called recordUserTotalBadges with ", uuid)
    let totalBadgesForUser = -1
    if (uuid) {
      try {
        const totalBadgesForUserResponse = await getUserTotalScorecardBadges(uuid)
        if (totalBadgesForUserResponse && totalBadgesForUserResponse.data && totalBadgesForUserResponse.data.data) {
          totalBadgesForUser = totalBadgesForUserResponse.data.data.length
        }
      } catch (error) {
        console.log("Error in getting total claimed badges", error)
      }
      
      console.log("Total claimed badges ", totalBadgesForUser)
      if (totalBadgesForUser !== -1) {
        const todayDate = getCurrentDate()
        clevertap.event.push("USER_TOTAL_ARTIST_BADGES_MUSICNERD", {total: totalBadgesForUser, date: todayDate})
      }
    }
  }

  const handlePlayAgain = () => {
    posthog?.capture("start_over", {
      email: userInfo?.email,
    });
    handleEventPushClick(MUSICNERD_START_OVER, {
      email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
    });
    postArtistEvent(id, artistName, artistImage, INCREMENT_ARTIST_GAMEPLAY);
    navigate(`/quiz/${id}`, {
      state: {
        artistName: artistName,
        artistImage: artistImage,
      },
    });
  };

  const handleWebPopup = (artist_name) => {
    console.log("web_popup_clicked");
    handleEventPushClick(MUSICNERD_ARTIST_QUIZ_RESPONDED, {
      click: "web_popup_clicked",
      artistName: artist_name,
      artistId: id,
    });
  };

  useEffect(() => {
    if (artistName && sentPopup === false) {
      document.title = `${artistName} | Musicnerd - Name that tune`;
      handleWebPopup(artistName);
      setSentPopup(true);
      let firstTimePlay = localStorage.getItem("firstTimePlay");
      if (firstTimePlay === null || firstTimePlay === "true") {
        posthog?.capture("artist_quiz_started", {
          artist_id: id,
          artist_name: location.state?.artistName,
        });
        localStorage.setItem("firstTimePlay", "false");
      }
      setTimeout(() => {
        console.log("MUSICNERD_WEBPOPUP_2");
        handleEventPushClick(MUSICNERD_WEBPOPUP_2, {
          page: "artist_page",
          artistName: artistName,
          artistId: id,
        });
      }, 3000);
    }
  }, [artistName]);

  useEffect(() => {
    if (user) {
      // Identify sends an event, so you want may want to limit how often you call it
      posthog?.identify(user.uid, {
        email: user.email,
      });
      posthog?.capture("login", {
        email: user.email,
      });
      console.log("EVENT SENT ");
    }
  }, [posthog, user?.uid, user?.email]);

  useEffect(() => {
    if (userInfo?.email && artistName) {
      let getFirstTime = localStorage.getItem("firstTime");
      console.log("GETFIRST TIME ", getFirstTime);
      if (getFirstTime === null) {
        posthog?.capture("signup", {
          email: userInfo?.email,
        });
        console.log({
          email: userInfo?.email,
          artistName: artistName,
        });
        postArtistEvent(id, artistName, artistImage, INCREMENT_ARTIST_SIGNUP);
        handleEventPushClick(MUSICNERD_ACCOUNT_CREATED, {
          username: userInfo?.username,
          email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
          artistName: artistName,
        });
        handleEventPushClick(MUSICNERD_ACCOUNT_CREATED, {
          username: userInfo?.username,
          email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
          artistName: artistName,
        });
        console.log("Email sent", userInfo?.email);
        // const body = {
        //   email: userInfo?.email,
        //   artist_name: artistName ? artistName : "Music Fan",
        // };
        // fetch(`${process.env.REACT_APP_URL}/send-email`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(body),
        // })
        //   .then(() => {
        //     console.log("Email sent");
        //   })
        //   .catch((err) => {
        //     console.log(err?.message);
        //   });
        localStorage.setItem("firstTime", "false");
      } else {
        posthog?.capture("login", {
          email: userInfo?.email,
        });
      }
    }
  }, [userInfo, artistName]);

  useEffect(() => {
    if (accessToken) {
      getUser();
    }
  }, [accessToken]);

  const victoryAudio = new Audio(VictoryAudio);

  useEffect(() => {
    if (right) {
      textDisplay(parseInt(right));
    }
  }, [right, quizData?.length]);

  useEffect(() => {
    if (parseInt(right) === 100) {
      victoryAudio.play();
      setTimeout(() => {
        setShowConfetti(true);
      }, 1000);

      setTimeout(() => {
        setShowConfetti(false);
      }, 6000);
    } else if (parseInt(right) === 0) {
      const LoseAudio = new Audio(loseAudio);
      LoseAudio.play();
    }
  }, [right]);

  // useEffect(() => {
  //   console.log("CLAIMING NFT 2");
  //   if (userInfo) {
  //     claimNft(
  //       userInfo?.uuid,
  //       artistName,
  //       id,
  //       artistImage,
  //       0,
  //       right,
  //       time,
  //       percentile?.toFixed(2),
  //       userInfo?.wallet
  //     );
  //   } else {
  //     let wallet = localStorage.getItem("wallet");
  //     if (wallet) {
  //       claimNft(
  //         randomUsername,
  //         artistName,
  //         id,
  //         artistImage,
  //         0,
  //         right,
  //         time,
  //         percentile?.toFixed(2),
  //         wallet
  //       );
  //     }
  //   }
  // }, [userInfo])
// Add this before your component's return statement:

const hasClaimedRef = useRef(false);

useEffect(() => {
  const performClaim = async () => {
    // If already claimed, return early
    if (hasClaimedRef.current) return;    
    console.log("CLAIMING NFT 2");
    
    try {
      if (userInfo) {
        hasClaimedRef.current = true;
        await claimNft(
          userInfo?.uuid,
          artistName,
          id,
          artistImage,
          0,
          right,
          time,
          percentile?.toFixed(2),
          userInfo?.wallet
        );
      } else {
        let wallet = localStorage.getItem("wallet");
        if (wallet) {
          hasClaimedRef.current = true;
          await claimNft(
            randomUsername,
            artistName,
            id,
            artistImage,
            0,
            right,
            time,
            percentile?.toFixed(2),
            wallet
          );
        }
      }
    } catch (error) {
      console.error('Error claiming NFT:', error);
      // Reset the flag if there's an error, so we can try again
      hasClaimedRef.current = false;
    }
  };

  performClaim();
  }, [userInfo]);

  useEffect(() => {
    const fetchLocationData = async () => {
      const cachedData = localStorage.getItem("locationData");

      if (cachedData) {
        // Use cached data if available
        const { country, region } = JSON.parse(cachedData);
        setUserCountry(country);
        setUserRegion(region);
        setLocationDataReady(true);
      } else {
        // If no cached data, make an API call
        try {
          const response = await axios.get(
            `https://ipapi.co/json/?key=${process.env.REACT_APP_IP_API_KEY}`
          );
          const { country_name, region } = response.data;
          setUserCountry(country_name);
          setUserRegion(region);
          setLocationDataReady(true);

          // Cache the new data
          localStorage.setItem('locationData', JSON.stringify({ country: country_name, region }));
        } catch (error) {
          console.error("Error fetching location data:", error);
          setUserCountry("Unknown");
          setUserRegion("Unknown");
          setLocationDataReady(false);
        }
      }
    };
    fetchLocationData();
  }, []);

  const handlePostUserLocation = (username, uuid) => {
    if (locationDataReady) {
      postUserLocation(username, uuid, userCountry, userRegion);
    }
  };

  const handleGoogleSignIn = async () => {
    clevertap.setLogLevel(3);
    clevertap.init(CLEVERTAP_ACCOUNT_ID);

    setSaveLoading(true);
    const provider = new GoogleAuthProvider();
    if (accessToken && accessToken !== "") {
      try {
        setButtonText("Signing ...");
        handleEventPushClick(MUSICNERD_SAVE_SCORE, {
          email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
          artist: artistName,
          score: parseInt(right)
        });
        const old_username = localStorage.getItem("username");
        console.log("google sign in", old_username);
        handlePostUserLocation(old_username, userInfo?.uuid);
        const body = {
          old_username:
            old_username?.length > 0 ? old_username : randomUsername,
          username: userInfo.username,
        };
        console.log("1 PUT BODY ", body);
        await putArtistSongs(body, accessToken);

        // handleEventPushClick(MUSICNERD_SIGNUP, {
        //   email: userInfo?.email ? userInfo.email : "inboxdev.b@gmail.com",
        //   artist: artistName,
        // });
        const postScoreData = async () => {
          const body = {
            username: userInfo?.username,
            score: parseInt(right),
            artist_id: id,
            artist_name: artistName,
            artist_image: artistImage,
            time_taken: parseInt(time),
            percentile: parseFloat(percentile.toFixed(2)),
          };
          console.log("2 BODY ", body);
          await postArtistScore(body, accessToken);
          recordUserTotalBadges(userInfo?.uuid)
        };
        postScoreData();
        console.log("FIRST CLAIM");
        // await claimNft(
        //   userInfo?.uuid,
        //   artistName,
        //   id,
        //   artistImage,
        //   0,
        //   right,
        //   time,
        //   percentile?.toFixed(2),
        //   userInfo?.wallet
        // );
        localStorage.setItem("loggedIn", "true");
        let firstTime = localStorage.getItem("firstTime");
        // console.log("firstTime in 1", firstTime);
        if (firstTime === "false") {
          await sleep(3000);
          // const originSite = localStorage.getItem('originSite');
          // if (originSite) {
          //   // Remove any trailing slashes from originSite and ensure proper URL format
          //   const cleanOriginSite = originSite.replace(/\/+$/, '').replace(/^https?:\/\//, '');
          //   // Clear the stored origin site
          //   localStorage.removeItem('originSite');
          //   // Format artist name for URL and redirect
          //   const urlFriendlyArtistName = formatArtistNameForUrl(artistName);
            
          //   // Add proper protocol to URL
          //   window.location.href = `https://${cleanOriginSite}`;
          // } else {
          //   // Default navigation if no origin site
          //   if (window.location.href.includes("musicnerd.io")) {
          //     navigate(`/leaderboard/${id}`, {
          //       state: { score: right, time: time, percentile: percentile },
          //     });
          //   } else {
          //     navigate(`/quiz/leaderboard/${id}`, {
          //       state: { score: right, time: time, percentile: percentile },
          //     });
          //   }
          // }
          if (window.location.href.includes("musicnerd.io")) {
            navigate(`/leaderboard/${id}`, {
              state: { score: right, time: time, percentile: percentile },
            });
          } else {
            navigate(`/quiz/leaderboard/${id}`, {
              state: { score: right, time: time, percentile: percentile },
            });
          }
        }
      } catch (e) {
        console.log("error", e);
      } finally {
        setSaveLoading(false);
      }
    } else {
      try {
        const result = await signInWithPopup(auth, provider);
        setUser(result.user);
        clevertap.profile.push({
          Site: {
            Name: result.user?.displayName,
            Email: result.user?.email,
          },
        });
        console.log(result.user);

        if (result.user) {
          try {
            // Get token first since we need it for checkIfNewUser
            const token = await result.user.getIdToken();
            setAccessToken(token);

            const old_username = localStorage.getItem("username");
            handlePostUserLocation(old_username, result.user.uid);


            let checkIfReferralUsed = localStorage.getItem("referralCodeUsed");
            // console.log("userToken", result.user.metadata.createdAt)
            const isNewUser = await checkUserReferral(result.user.metadata.createdAt)
            // console.log("isNewUser", isNewUser)
            if (checkIfReferralUsed === null && isNewUser) {
              let ref = localStorage.getItem("ref");
              console.log("Referral code", ref);
              const body = {
                referralCode: ref,
                referreduser: result.user.email,
              };
              fetch(`https://cruncher.asset.money/misc/referral`, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
              })
                .then((response) => {
                  console.log(response);
                  localStorage.setItem("referralCodeUsed", "true");
                })
                .catch((err) => {
                  console.log(err?.message);
                });
            }

            // console.log("2 PUT BODY ", body);
            result.user.getIdToken().then(async (token) => {
              console.log("TOKEN ", token);
              // result.user.getIdToken().then(async (token) => {
              //   await putArtistSongs(body, token);
              //   handleEventPushClick("MUSICNERD_SIGNUP", {
              //     email: result.user?.email
              //       ? result.user.email
              //       : "inboxdev.b@gmail.com",
              //     artist: artistName,
              //   });
              //   setAccessToken(token);
              //   localStorage.setItem("loggedIn", "true");
              // });

              const userInfoFromApi = await getUserInfo(token)
              console.log("userinfo from api", userInfoFromApi)
              let usernameFromApi = ""
              if (userInfoFromApi && userInfoFromApi.username) {
                usernameFromApi = userInfoFromApi.username
              }

              const body = {
                old_username:
                  old_username?.length > 0 ? old_username : generateUsername(),
                username: usernameFromApi ? usernameFromApi : result.user.displayName,
              };
              console.log("2 PUT BODY ", body);
              await putArtistSongs(body, token);
              handleEventPushClick(MUSICNERD_SIGNUP, {
                email: result.user?.email
                  ? result.user.email
                  : "inboxdev.b@gmail.com",
                artist: artistName,
              });
              setAccessToken(token);
              localStorage.setItem("loggedIn", "true");

              const postScoreData = async () => {
                const body = {
                  username: result.user.displayName,
                  score: parseInt(right),
                  artist_id: id,
                  artist_name: artistName,
                  artist_image: artistImage,
                  time_taken: parseInt(time),
                  percentile: parseFloat(percentile.toFixed(2)),
                };
                await postArtistScore(body, token);
                recordUserTotalBadges(result.user.uid)
              };
              postScoreData();
              let getFirstTime = localStorage.getItem("firstTime");
              console.log(
                "getFirstTime in 2",
                getFirstTime,
                result.user.email,
                artistName
              );
              if (result.user.email && artistName) {
                if (getFirstTime === null) {
                  posthog?.capture("signup", {
                    email: result.user.email
                      ? result.user.email
                      : "inboxdev.b@gmail.com",
                  });
                  console.log({
                    email: result.user.email,
                    artistName: artistName,
                  });
                  postArtistEvent(
                    id,
                    artistName,
                    artistImage,
                    INCREMENT_ARTIST_SIGNUP
                  );
                  handleEventPushClick(MUSICNERD_ACCOUNT_CREATED, {
                    username: result.user.displayName,
                    email: result.user.email
                      ? result.user.email
                      : "inboxdev.b@gmail.com",
                    artistName: artistName,
                  });
                  handleEventPushClick(MUSICNERD_ACCOUNT_CREATED, {
                    username: result.user.displayName,
                    email: result.user.email
                      ? result.user.email
                      : "inboxdev.b@gmail.com",
                    artistName: artistName,
                  });
                  console.log("Email sent", result.user.email);
                  // const body = {
                  //   email: result.user.email,
                  //   artist_name: artistName ? artistName : "Music Fan",
                  // };
                  // fetch(`${process.env.REACT_APP_URL}/send-email`, {
                  //   method: "POST",
                  //   headers: {
                  //     "Content-Type": "application/json",
                  //   },
                  //   body: JSON.stringify(body),
                  // })
                  //   .then(() => {
                  //     console.log("Email sent");
                  //   })
                  //   .catch((err) => {
                  //     console.log(err?.message);
                  //   });
                  localStorage.setItem("firstTime", "false");
                }
              }
              setUserClaimNft(true);
              // console.log("Before sleep")
              await sleep(5000);
              // console.log("After sleep")
              if (window.location.href.includes("musicnerd.io")) {
                navigate(`/leaderboard/${id}`, {
                  state: { score: right, time: time, percentile: percentile },
                });
              } else {
                navigate(`/quiz/leaderboard/${id}`, {
                  state: { score: right, time: time, percentile: percentile },
                });
              }
              // setAccessToken(token);
            });
          } catch (e) {
            console.log("error", e);
          }
        }
        setShowAuth(false);
      } catch (error) {
        console.error("Google Sign-In Error:", error);
      } finally {
        setSaveLoading(false);
      }
    }
  };
  const getArtistData = async (id) => {
    const response = await getArtistSongs(id);
    // console.log("RESPONSE ", response.data.data);
    // console.log("LOCATION ", location.state)
    console.log(
      "location.state?.artist_image",
      location.state?.artist_image,
      response.data.data.artist_image
    );
    console.log(
      "location.state?.artist_name",
      location.state?.artist_name,
      response.data.data.artist_name
    );
    setArtistImage(
      location.state?.artist_image
        ? location?.state?.artist_image
        : response.data.data.artist_image
    );
    setArtistName(
      location.state?.artist_name
        ? location.state.artist_name.charAt(0).toUpperCase() +
            location.state.artist_name.slice(1)
        : response?.data?.data?.artist_name
    );
    setQuizData(response?.data?.data?.artist_quiz);
  };

  useEffect(() => {
    getArtistData(id);
  }, [id]);

  useEffect(() => {
    console.log("location params useEffect:", location.state)
    if (quizData?.length > 0) {
      if (location.state?.artist_name?.length > 0) {
        setArtistName(location.state.artist_name);
      }

      if (location.state?.artist_image?.length > 0) {
        setArtistImage(location.state.artist_image);
      }

      if (location?.state?.right) {
        setRight(location.state.right);
      }

      if (location?.state?.time) {
        setTime(location.state.time);
      }
    }
  }, [location.state?.artist_name, location.state?.artist_image, quizData, location.state?.right, location?.state?.time]);

  const createWalletForUser = async (username) => {
    try {
      let walletExists = localStorage.getItem("wallet");
      if (walletExists) {
        return;
      }
      const body = {
        username: username,
      };
      console.log("1 BODY WALLET", body);
      let response = await postUserWallet(body);
      console.log("response WALLET", response);
      let data = response.data;
      console.log("data WALLET", data);
      if (data?.wallet) {
        localStorage.setItem("wallet", data.wallet);
      }
      return data;
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    let loggedIn = localStorage.getItem("loggedIn");
    console.log("in the logged in useEffect", loggedIn, userInfo, right, time, savedData)

    if (typeof artistName === "string" && artistName.length > 0 && right !== null && time !== null && time > 0) {
      if (loggedIn) {
        if (savedData && userInfo?.username?.length > 0) {
          createWalletForUser(userInfo?.username);
          handlePostUserLocation(userInfo?.username, userInfo?.uuid);
          let usernameFromLocalStorage = localStorage.getItem("username")
          const body = {
            old_username: usernameFromLocalStorage,
            username: userInfo?.username,
          };
          console.log("3 PUT BODY ", body);
          putArtistSongs(body, accessToken);

          // console.log("ACCESS TOKEN ", accessToken);
          // console.log("USER INFO ", userInfo);
          // if (
          //   typeof userInfo?.username === "string" &&
          //   userInfo?.username.length > 0
          // ) {
          //   const postData = async () => {
          //     // console.log("userInfo 1 ", userInfo);
          //     const body = {
          //       uuid: userInfo?.uuid,
          //       username: userInfo?.username,
          //       score: parseInt(right),
          //       artist_id: id,
          //       artist_name: artistName,
          //       artist_image: artistImage,
          //       time: parseInt(time),
          //       logged: true,
          //     };
          //     console.log("2 BODY ", body);
          //     await postArtistSongs(body, accessToken);
          //   };

          //   postData();
          //   setSavedData(true);
          // }
        } else if (!savedData && userInfo?.username?.length > 0) {
          if (
            typeof userInfo?.username === "string" &&
            userInfo?.username.length > 0
          ) {
            const postData = async () => {
              // console.log("userInfo 1 ", userInfo);
              const body = {
                uuid: userInfo?.uuid,
                username: userInfo?.username,
                score: parseInt(right),
                artist_id: id,
                artist_name: artistName,
                artist_image: artistImage,
                time: parseInt(time),
                logged: true,
              };
              console.log("2 BODY ", body);
              await postArtistSongs(body, accessToken);
            };

            postData();
            setSavedData(true);
          }
        }
      } else {
        if (!savedData) {
          let savedUsername = localStorage.getItem("username");

          if (typeof savedUsername !== "string" || savedUsername.length === 0) {
            savedUsername = generateUsername(); // Generate a username of 10 characters
            localStorage.setItem("username", savedUsername);

            createWalletForUser(savedUsername);
          }
          setRandomUsername(savedUsername);
          handlePostUserLocation(savedUsername, userInfo?.uuid);
          // Define async function
          const postData = async () => {
            const body = {
              uuid: "",
              username: savedUsername,
              score: parseInt(right),
              artist_id: id,
              artist_name: artistName,
              artist_image: artistImage,
              time: parseInt(time),
              logged: false,
            };
            console.log("1 BODY ", body);
            await postArtistSongs(body);
          };

          postData();
          setSavedData(true);
        }
      }
    }
  }, [userInfo, artistName, userCountry, userRegion, right, time]);

  // console.log('generate username is', randomUsername)

  const getScores = async (id) => {
    const response = await getArtistScores(id);
    setArtistScores(response.data.data);
  };

  useEffect(() => {
    // Fetch the data when the component mounts
    getScores(id);
  }, [id]);

  function calculatePercentile(artistScores, userScore, userTime) {
    if (!artistScores || artistScores.length <= 1) {
      return 0;
    }

    // console.log('artistScores are ', artistScores)
    let userEfficiency = userScore / userTime;

    // console.log('userEfficiency is ', userEfficiency)
    // Calculate efficiencies for artist scores
    let efficiencies = artistScores.map((obj) => {
      let efficiency = obj.score / obj.time;
      return isNaN(efficiency) ? 0 : efficiency;
    });

    // Add user's efficiency to the array
    efficiencies.push(userEfficiency);

    // Sort efficiencies in descending order
    efficiencies.sort((a, b) => b - a);

    console.log("efficiencies are ", efficiencies.length);
    // Calculate the user's rank in efficiencies
    let rank = efficiencies.indexOf(userEfficiency) + 1;
    console.log("rank is ", rank);
    let percentile = (rank / efficiencies.length) * 100;

    // Round to 2 decimal places, ensuring it's not 100%
    percentile = Math.min(Math.round(percentile * 100) / 100, 99.99);

    return percentile;
  }

  useEffect(() => {
    setPercentile(calculatePercentile(artistScores, right, time));
  }, [artistScores, time]);

  useEffect(() => {
    setTimeout(() => {
      setRightLoader(false);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   // First check URL params
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const returnUrl = urlParams.get('returnUrl');
    
  //   // Then check location state
  //   const stateReturnUrl = location.state?.returnUrl;
    
  //   if (returnUrl) {
  //     localStorage.setItem('originSite', returnUrl);
  //   } else if (stateReturnUrl) {
  //     localStorage.setItem('originSite', stateReturnUrl);
  //   } else if (document.referrer && !localStorage.getItem('originSite')) {
  //     // Fall back to referrer if no return URL specified
  //     localStorage.setItem('originSite', document.referrer);
  //   }
  // }, [location.state]);

  if (!right && !time && !rightLoader) {
    return (
      <div className="flex flex-col">
        <div className="bg-blue flex flex-col w-screen h-screen mt-7 gap-4 items-center">
          <div className="flex items-center">
            <Link to="/">
              <img src={MuzifyLogo} alt="muzify" className="w-32" />
            </Link>
          </div>
          <div className="flex flex-col justify-center gap-3 items-center min-h-[80vh] text-white">
            <h3 className="text-7xl text-extrabold text-[rgba(239,209,101,1)]">
              PAGE NOT FOUND
            </h3>
            <Link to="/" className="text-[rgba(239,209,101,1)]">
              Go back to the homepage
            </Link>
          </div>
        </div>
      </div>
    );
  }

  console.log("percentile is", percentile);

  return (
    <div className="flex flex-col">
      {/* <Header /> */}
      <div className="bg-blue flex flex-col w-screen h-screen md:mt-7 mt-3 gap-4 items-center">
        {showConfetti && <Confetti />}
        <div className="flex items-center">
          <Link to="/">
            <img src={MuzifyLogo} alt="muzify" className="w-32" />
          </Link>
          {accessToken && (
            <div className="flex justify-end mr-5 md:mr-10 absolute right-0">
              <Link to="/profile">
                <img
                  src={userInfo?.profilepic}
                  alt=""
                  className="h-8 w-8 md:h-12 md:w-12 rounded-full bg-gray-800"
                />
              </Link>
            </div>
          )}
        </div>
        {loading ? (
          <div className="flex flex-col justify-center items-center">
            <span className="text-2xl text-center font-extrabold text-[rgba(239,209,101,1)] cabinet-grotesk">
              Loading...
            </span>
          </div>
        ) : (
          <div>
            <div className="flex flex-col justify-center gap-3 items-center">
              <p className="md:text-5xl text-2xl md:px-0 px-4 text-center text-[rgba(239,209,101,1)] cabinet-grotesk font-bold">
                {text}
              </p>
              <p className="md:text-xl text-lg text-center text-white cabinet-grotesk font-extrabold">
                Your fandom score-card
              </p>
              <div className={`${accessToken ? '' : 'blur-md' }`}>
                <ScoreCard
                  right={right / 10}
                  artistName={artistName}
                  time={time}
                  artistImage={artistImage}
                  percentile={percentile}
                />
              </div>
              <div className="flex flex-col mb-3 items-center justify-center">
                <p className=" text-[rgba(203,203,203,1)] font-bold md:text-base cabinet-grotesk md:px-20">
                  {accessToken ? "Save to see fan leaderboard" : "Sign up to see your scorecard and leaderboard"}
                </p>
              </div>
            </div>
            <div className="flex md:flex-row flex-col justify-center items-center gap-5 mb-12">
              <button
                className="bg-[rgba(239,209,101,1)] text-black inter w-80 md:w-72 h-12 rounded-full"
                onClick={handleGoogleSignIn}
                disabled={saveLoading}
              >
                <p className="cabinet-grotesk text-base font-bold">
                  {saveLoading
                    ? "Saving..."
                    : accessToken
                    ? "Save Score"
                    : buttonText}
                </p>
              </button>
              <button
                className="border-[rgba(239,209,101,1)] border-2 inter w-80 md:w-72 h-12 rounded-full"
                onClick={handlePlayAgain}
              >
                <p className="cabinet-grotesk font-bold text-base text-[rgba(239,209,101,1)]">
                  Start Over
                </p>
              </button>
            </div>
          </div>
        )}
      </div>
      <a className="text-[#141a1e] text-sm" href="https://muzify.com/quiz">
          Muzify
      </a>
      <a className="text-[#141a1e] text-sm" href="https://imfeeling.xyz">
          Imfeeling
      </a>
    </div>
  );
};

export default ScoreScreen;
